import axios from 'axios'

const duetControllerBase = process.env.VUE_APP_DUET_CONTROLLER
const raspberryBase = process.env.VUE_APP_MODE_PRODUCTION ? process.env.VUE_APP_RASPBERRY_API_BASE : process.env.VUE_APP_RASPBERRY_API_BASE_PROD
const duetControllerAxios = axios.create({
  baseURL: duetControllerBase
})
const raspberryAxios = axios.create({
  baseURL: raspberryBase // 'http://localhost:5000'
})
axios.defaults.baseURL = `${duetControllerBase}`

export { duetControllerAxios, raspberryAxios }

import Vue from 'vue'
import VueRouter from 'vue-router'
import UploadView from '@/views/UploadView'
import StartView from '@/views/StartView'
import Keyboard from '@/views/Keyboard'
// import KeyboardWoAPI from '@/views/KeyboardWoAPI'
import soleLength from '@/views/SoleLengthAnimaTest'
import MotorDiameterLength from '@/views/MotorDiameterLengthAnimaTest'
import SendAndGoStart from '@/views/SendAndGoStartAnimaTest'
import testViewCSSLayout from '@/views/TestViewCSSLayout'
import CodeRuning from '@/views/CodeRuning'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: Keyboard
  },
  {
    path: '/keyboard',
    name: 'keyboard',
    component: Keyboard
  },
  {
    path: '/home',
    name: 'home',
    component: StartView
  },
  {
    path: '/upload',
    name: 'upload',
    component: UploadView
  },
  {
    path: '/testview',
    name: 'testview',
    component: testViewCSSLayout
  },
  {
    path: '/sole_length',
    name: 'sole_length',
    component: soleLength
  },
  {
    path: '/motor_diam',
    name: 'motor_diam',
    component: MotorDiameterLength
  },
  {
    path: '/go',
    name: 'go',
    component: SendAndGoStart
  },
  {
    path: '/coderun',
    name: 'coderun',
    component: CodeRuning
  },
  {
    path: '*',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      // return { path: '/', query: { q: to.params.searchText } }
      return { path: '/', query: { q: to.params } }
    }
    /*
      path: '*',
      name: 'go',
      component:NotFound,
     */
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div class="main-container">
    <main>
      <left-right-component class="left-navigation" text="<" direction="LEFT" @navigationTriggered="navigatePage"/>
      <div class="main-content">
        <div class="flex-col-diameter">
          <img class="diameter-col-header" src="../assets/images/svg/diameter_symbol.svg">
          <div class="toggle-btn-col">
            <button class="toggle-btn" @click="toggle('diam',3.6)" :class="[diamFirst ? 'active': '']">
              3.6
            </button>

            <button class="toggle-btn" @click="toggle('diam', 4.1)" :class="[!diamFirst? 'active': '']">
              4.1
            </button>
          </div>
          <!-- <div class="button-group-container">
            <button-group
              text="3,6"
              @btnClicked="buttonClicked"
              group="diameter"
              :range="diameterRange"
              label="btn1"
              :index="0"
            />
            <button-group
              text="4,1"
              @btnClicked="buttonClicked"
              group="diameter"
              :range="diameterRange"
              label="btn2"
              :index="1"
            />
          </div>-->
        </div>
        <div class="flex-col-depth">
          <img class="depth-col-header" src="../assets/images/svg/depth_symbol.svg">
          <div class="toggle-btn-col">
            <button class="toggle-btn-depth" @click="toggle('depth', 7)" :class="[depthFirst ? 'active': '']">
              7
            </button>
            <button class="toggle-btn-depth" @click="toggle('depth', 9)" :class="[!depthFirst? 'active': '']">
              9
            </button>
          </div>
          <!-- <div class="button-group-container">
            <button-group
              text="7"
              @btnClicked="buttonClicked"
              group="length"
              :range="depthRange"
              label="btn3" :index="0" />
            <button-group
              text="9"
              @btnClicked="buttonClicked"
              group="length"
              :range="depthRange"
              label="btn4" :index="1" />
          </div>-->
        </div>
        <div class="flex-row-depth">
          <div class="offset-button-continer">
            <div class="input-container">
              <input type="number"
              id="inputOffset"
              class="input-offset"
              v-bind:value="drillOffset"
              name="inputOffset">
            </div>
            <div class="button-group-container">
              <v-btn block elevation="0" class="offset-btn" @click="increment"> + </v-btn>
              <v-btn block elevation="0" class="offset-btn" @click="decrement"> - </v-btn>
            </div>
          </div>
          <img class="offset-img" src="../assets/images/svg/ski_symbol.svg">
        </div>
      </div>
      <left-right-component class="right-navigation" text=">" direction="RIGHT" @navigationTriggered="navigatePage"/>
    </main>
    <footer class="footer">
      <div class="navigation-footer-button-large">
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/home')">
          <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
          <img src="../assets/images/svg/home_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
          <img src="../assets/images/svg/binding_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
          <img src="../assets/images/svg/boot_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
          <img src="../assets/images/svg/solelength_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
          <img src="../assets/images/svg/go_symbol.svg">
        </v-btn>
      </div>
      <div class="navigation-footer-button-small">
        <left-right-component class="left-navigation-bottom" text="<" direction="LEFT" @navigationTriggered="navigatePage"/>
        <v-speed-dial
          v-model="menuButtonFab.fab"
          :top="menuButtonFab.top"
          :bottom="menuButtonFab.bottom"
          :right="menuButtonFab.right"
          :left="menuButtonFab.left"
          :direction="menuButtonFab.direction"
          :open-on-hover="menuButtonFab.hover"
          :transition="menuButtonFab.transition"
          class="button-speed-dial"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuButtonFab.fab"
              color="blue darken-2"
              dark
              fab
              small
            >
              <v-icon v-if="menuButtonFab.fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                <!-- mdi-account-circle -->
                mdi-cog-outline
              </v-icon>
            </v-btn>
          </template>
          <v-btn color="var(--nav-button-grey)" @click="handleMenuNavigationButton('/setting')">
            <img src="../assets/images/svg/setting_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/')">
            <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
            <img src="../assets/images/svg/home_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
            <img src="../assets/images/svg/binding_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
            <img src="../assets/images/svg/boot_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
            <img src="../assets/images/svg/solelength_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
            <img src="../assets/images/svg/go_symbol.svg">
          </v-btn>
        </v-speed-dial>
        <left-right-component class="right-navigation-bottom" text=">" direction="RIGHT" @navigationTriggered="navigatePage"/>
      </div>
    </footer>
  </div>
</template>

<script>
/*
import ButtonNumberOperator from '@/components/ButtonNumberOperator'
import NumericalKeyboard from '@/components/NumericalKeyboard'
import NavigationButton from '@/components/NavigationButton'
*/
import LeftRightComponent from '@/components/LeftRightComponent.vue'
// import HomeButton from '@/components/HomeButton'
// import ButtonGroup from '@/components/ButtonGroup'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SoleLength',
  components: {
    LeftRightComponent
    // ButtonGroup
    // HomeButton
    // ButtonNumberOperator,
    // NumericalKeyboard,
    // NavigationButton
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    leftIcon: '<',
    rightIcon: '>',
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false,
    keyboardInputValue: 0,
    drillOffset: 0,
    drillDiam: 3.6,
    drillDepth: 7,
    diamFirst: true,
    depthFirst: true,
    menuButtonFab: {
      direction: 'top', // top, right, left, bottom
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      /*
      top: false,
      right: true,
      bottom: true,
      left: false,
       */
      transition: 'slide-y-reverse-transition'
    },
    diameterValues: [3.6, 4.1],
    depthValues: [7, 9],
    diameterRange: [
      true,
      false
    ],
    depthRange: [
      false,
      true
    ]
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
  },
  computed: {
    ...mapState([
      'valueEveryWhere'
    ]),
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    numberRules () {
      return [
        (v) => (!isNaN(parseFloat(v)) && !!v && !v.toString().includes(',')) || 'Ska vara ifyllt och numeriskt!'
      ]
    },
    booleanRules () {
      return [
        (v) => this.booleanValues.includes(v) || 'This field is required !'
      ]
    },
    dynamicFontStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFontSizeStyle(20)
        case 'sm': return this.getFontSizeStyle(25)
        case 'md': return this.getFontSizeStyle(30)
        case 'lg': return this.getFontSizeStyle(35)
        case 'xl': return this.getFontSizeStyle(35)
        default:
          return this.getFontSizeStyle(30)
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'CHANGE_DIAMETER',
      'CHANGE_DEPTH',
      'CHANGE_OFFSET'
    ]),
    toggle (type, value) {
      console.log('Pressed type: ', type)
      if (type === 'diam') {
        this.drillDiam = value
        this.diamFirst = !this.diamFirst
        console.log(this.drillDiam)
      } else {
        this.drillDepth = value
        this.depthFirst = !this.depthFirst
        console.log(this.drillDepth)
      }
    },
    handleMenuNavigationButton (page) {
      this.$router.push(page)
    },
    buttonClicked (e) {
      console.log('buttonClick ed>>>>>>>>>>>>>>>>>')
      if (e.group === 'diameter') {
        const range = e.range.map(e => false)
        range[e.index] = true
        this.diameterRange = range
        console.log(e)
      } else {
        const range = e.range.map(e => false)
        range[e.index] = true
        this.depthRange = range
        console.log(e)
      }
    },
    navigatePage (dir) {
      console.log('***************')
      console.log('navigatePage...', dir)
      switch (dir) {
        case 'LEFT':
          console.log('LEFT')
          this.$router.push('/sole_length')
          break
        case 'RIGHT':
          console.log('RIGHT')
          // depthRange --> depthRange
          // this.CHANGE_DIAMETER(this.diameterValues[this.diameterRange.indexOf(true)])
          this.CHANGE_DIAMETER(this.drillDiam)
          // this.CHANGE_DEPTH(this.depthValues[this.depthRange.indexOf(true)])
          this.CHANGE_DEPTH(this.drillDepth)
          this.CHANGE_OFFSET(this.drillOffset)
          this.$router.push('/go')
          break
        default:
          console.log('default')
      }
    },
    getFontSizeStyle (fsize) {
      return {
        'font-size': `${fsize}px`
      }
    },
    increment () {
      this.drillOffset++
    },
    decrement () {
      this.drillOffset--
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
}

.button-speed-dial {
  /*
  position: fixed;
  top: 50%;
  left: 5px;
   */
}

.header {
  /*border: 3px solid blue;*/
  border-bottom: 1px solid var(--flame-pea);
  position: fixed;
  height: 7vh;
  right:0;
  left: 0;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer {
  /* border: 3px solid red;*/
  position: fixed;
  height: 11vh;
  right:0;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.main-content {
  /* border: 2px solid darkgoldenrod;*/
  margin-left: 23vh;
  margin-right: 23vh;
  margin-top: 8vh;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.main-content .flex-col-separator {
  color: var(--gravel);
  font-family: "Komika";
  font-size: var(--font-size-m);
  height: 53px;
  min-height: 30px;
  text-align: center;
  /*border: 1px solid red;*/
}

.button-group-container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 100%;
  height: 1000%;
}

.diameter-col-header, .depth-col-header {
  height: 30%;
  width: 100%;
}

.flex-col-diameter {
  /*border: 2px solid #7b02fc;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.flex-col-depth {
  /*border: 2px solid #02fc23;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.text-gravel{
  color: var(--gravel);
  font-family: "Komika";
  font-size: var(--font-size-m);
  height: 53px;
  min-height: 30px;
  text-align: center;
}

.left-navigation {
  margin: 5px;
  height: 79vh;
  width: 10vh;
  position: fixed;
  top:7.7vh;
  left: 0;
  bottom: 15vh;
}

.right-navigation {
  /* border: 2px solid darkorange;*/
  margin: 5px;
  height: 79vh;
  width: 10vh;
  position: fixed;
  top:7.7vh;
  right: 0;
  bottom: 15vh;
}
.navigation-footer-button-large {
  display: flex;
  /*border: 2px solid greenyellow;*/
  gap: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  width: 100%;
}

.flex-row-depth {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
}

.input-offset{
  border: 2px dashed;
  border-radius: 10px;
  border-color: var(--ski-color);
  margin-bottom: 0.7rem;
  height: 100%;
  width: 100%;
  text-align: center;
  font-family: 'Komika';
  color: var(--ski-color);
  font-size: var(--font-size-m);
}

.input-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 30%;
  width: 100%;
}

.offset-img{
  max-height: 100%;
}

.offset-button-continer{
  display:flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.offset-btn {
  color: var(--ski-color);
  border: 2px solid;
  border-color: var(--flame);
  border-radius: 10px;
  min-height: 80px;
  height: 100%;
  width: 100%;
  font-family: 'Komika';
  font-size: var(--font-size-l);
}

.toggle-btn-col{
  display:flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 100%;
  height: 100%;
}

.toggle-btn{
  background-color: var(--hint-of-red);
  border: 2px solid var(--gravel);
  border-radius: 10px;
  min-height: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Komika";
  color: var(--gravel);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  text-align: center;
}

.toggle-btn.active{
  background-color: var(--gravel);
  color: var(--hint-of-red);
}

.toggle-btn-depth{
  background-color: var(--hint-of-red);
  border: 2px solid var(--flame-pea);
  border-radius: 10px;
  min-height: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: "Komika";
  color: var(--flame-pea);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  text-align: center;
}

.toggle-btn-depth.active{
  background-color: var(--flame-pea);
  color: var(--hint-of-red);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .left-navigation {
    display: none;
  }
  .right-navigation {
    display: none;
  }
  .main-content {
    margin-left: 1vh;
    margin-right: 1vh;
  }
  .navigation-footer-button-large {
    display: none;
  }
  .navigation-footer-button-small {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .navigation-footer-button-small .left-navigation-bottom {
    width: 40.99%;
    height: 10vh;
    border-radius: 0;
  }
  .navigation-footer-button-small .right-navigation-bottom {
    width: 40.99%;
    height: 10vh;
    border-radius: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navigation-footer-button-small {
    display: none;
  }
}

</style>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
</style>

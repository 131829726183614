<template>
  <div class="title-component">
    <h1 class="text-title" :style="myStyles">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  name: 'TitleComponent',
  props: [
    'text',
    'font_size'
  ],
  data () {
    return {
      height: 60,
      fontsize: 36,
      fontweight: 400,
      position: 'relative',
      letterspacing: 7,
      minwidth: 281
    }
  },
  methods: {
    increase () {
      this.height += 10
    },
    getFormatStyle (height, fsize, fweight, lspacing, minwidth) {
      return {
        height: `${height}px`,
        'font-size': `${fsize}px`,
        'font-weight': fweight,
        'letter-spacing': `${lspacing}px`,
        'min-width': `${minwidth}px`
      }
    },
    getFontSizeStyle (fsize) {
      return {
        'font-size': `${fsize}px`
      }
    }
  },
  computed: {
    myStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFontSizeStyle(18)
        case 'sm': return this.getFontSizeStyle(19)
        case 'md': return this.getFontSizeStyle(27)
        case 'lg': return this.getFontSizeStyle(35)
        case 'xl': return this.getFontSizeStyle(45)
        default:
          return this.getFontSizeStyle(30)
      }
    }
  }
}
</script>

<style>
.title-component {
  border: 1px none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  /*background-color: var(--hint-of-red);*/
}

.text-title{
  color: var(--flame-pea);
  font-family: "Komika";
  text-align: center;
}
</style>

<template>
  <div class="num-pad">
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(1)">1</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(2)">2</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(3)">3</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(4)">4</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(5)">5</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(6)">6</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(7)">7</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(8)">8</v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(9)">9</v-btn>
    <v-btn
      elevation="0"
      class="num-pad-button"
      color="var(--gravel)"
      text
      icon
      @click="handleClickEvent('DOT')"
    >
      <v-icon large>mdi-circle-small</v-icon>
    </v-btn>
    <v-btn elevation="0" class="num-pad-button" @click="handleClickEvent(0)">0</v-btn>
    <v-btn
      elevation="0"
      class="num-pad-button"
      color="var(--gravel)"
      text
      icon
      @click="handleClickEvent('UNDO')"
    >
      <v-icon large>mdi-backspace</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'styleExplorer',
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  data: () => ({
    variable: false
  }),
  mounted () {
  },
  computed: {
  },
  filters: {
  },
  methods: {
    handleClickEvent (element) {
      console.log('Value clicked=', element)
      this.$emit('numberEntered', element)
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}
</script>

<style scoped>

.num-pad{
  /*border: 2px solid black;*/
  height: 100%;
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
}

.num-pad-button{
  width: 100%;
  height: 100%;
  border: 2px solid var(--gravel);
  border-radius: 10px;
  background-color: var(--hint-of-red);
  font-family: "Komika";
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: var(--font-size-m);
  color: var(--gravel);
}

.v-btn:not(.v-btn--round).v-size--default{
  height: 100%;
}

</style>

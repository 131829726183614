import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { duetControllerAxios, raspberryAxios } from '@/utils/axiosApi'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    valueEveryWhere: {
      binding: {
        name: '',
        order: null
      },
      length: '',
      offset: '',
      diameter: '',
      depth: ''
    },
    bindings: {
      current: null,
      g_code: null,
      data: {
        constant: [],
        visible: []
      }
    }
  },
  getters: {
  },
  mutations: {
    SET_BINDING (state, payload) {
      state.valueEveryWhere.binding = payload
    },
    SET_LENGTH (state, payload) {
      state.valueEveryWhere.length = payload
    },
    SET_OFFSET (state, payload) {
      state.valueEveryWhere.offset = payload
    },
    SET_DIAMETER (state, payload) {
      state.valueEveryWhere.diameter = payload
    },
    SET_DEPTH (state, payload) {
      state.valueEveryWhere.depth = payload
    }
  },
  actions: {
    CHANGE_BINDING (context, payload) {
      context.commit('SET_BINDING', payload)
    },
    CHANGE_LENGTH (context, payload) {
      context.commit('SET_LENGTH', payload)
    },
    CHANGE_OFFSET (context, payload) {
      context.commit('SET_OFFSET', payload)
    },
    CHANGE_DIAMETER (context, payload) {
      context.commit('SET_DIAMETER', payload)
    },
    CHANGE_DEPTH (context, payload) {
      context.commit('SET_DEPTH', payload)
    },
    async shutdown (context, query) {
      console.log('query')
      console.log(query)
      return new Promise((resolve, reject) => {
        axios.get(query)
          .then((response) => {
            console.log('Result of Axios Api Call')
            console.log(response.data)
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in API_POST query =' + query)
            console.log(error.response)
          })
      })
    },
    async sendGCode2 (context, payload) {
      console.log('============ sendGCode2 action')
      // http://192.168.1.207/rr_gcode?gcode=G92%20X0%20Y0%20Z0
      const url = 'rr_gcode?gcode='
      const { gcode } = payload
      console.log('gcode')
      console.log(gcode)
      console.log('context')
      console.log(context)
      const query = url + gcode
      console.log('query')
      console.log(query)
      return new Promise((resolve, reject) => {
        duetControllerAxios.get(query, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            console.log('Result of Axios Api Call')
            console.log(response.data)
            console.log('DATA:')
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in sendGCode2 query =' + query)
            console.log(error.response)
          })
      })
    },
    async sendGCode (context, payload) {
      console.log('============ sendGCode action')
      // http://192.168.1.207/rr_gcode?gcode=G92%20X0%20Y0%20Z0
      const url = 'http://192.168.1.207/rr_gcode?gcode='
      const { gcode } = payload
      console.log('gcode')
      console.log(gcode)
      console.log('context')
      console.log(context)
      const query = url + gcode
      console.log('query')
      console.log(query)
      return new Promise((resolve, reject) => {
        axios.get(query)
          .then((response) => {
            console.log('Result of Axios Api Call')
            console.log(response.data)
            console.log('DATA:')
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in API_POST query =' + query)
            console.log(error.response)
          })
      })
    },
    async upload (context, payload) {
      console.log('============ upload action')
      const { query, data } = payload
      console.log('query')
      console.log(query)
      console.log('data')
      console.log(data)
      console.log('context')
      console.log(context)
      console.log('upload action <<<<<<<<<<<<')
      // const data = await axios.get('http://192.168.1.207/rr_connect?password=')
      return new Promise((resolve, reject) => {
        axios.post(query, data)
          .then((response) => {
            console.log('Result of Axios Api Call')
            console.log('DATA:')
            console.log(response.data)
            console.log('DATA:')
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in API_POST query =' + query)
            console.log('+++++++++++++++++++++++++++error.response')
            console.log(error.response)
          })
      })
    },
    async connect () {
      console.log('connect action')
      try {
        const data = await axios.get('http://192.168.1.207/rr_connect?password=')
        console.log(data.data)
      } catch (error) {
        alert(error)
        console.log(error)
      }
    },
    GET (query) {
      return new Promise((resolve, reject) => {
        axios.get(query, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log('Error in API_GET query =' + query)
            console.log(error.response)
          })
      })
    },
    GET_API_DUET_CONTROLLER (context, query) {
      console.log('query')
      console.log(query)
      return new Promise((resolve, reject) => {
        duetControllerAxios.get(query, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in API_GET query =' + query)
            console.log(error.response)
          })
      })
    },
    POST_API_DUET_CONTROLLER (context, payload) {
      const { query, data } = payload
      console.log('payload')
      console.log(payload)
      return new Promise((resolve, reject) => {
        duetControllerAxios.post(query, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.log('Error in API_GET query =' + query)
            console.log(error.response)
          })
      })
    },
    GET_API_RASPBERRY (context, query) {
      console.log('query')
      console.log(query)
      return new Promise((resolve, reject) => {
        raspberryAxios.get(query, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in API_GET query =' + query)
            console.log(error.response)
          })
      })
    },
    POST_API_RASPBERRY (context, payload) {
      const { query, data } = payload
      console.log('payload')
      console.log(payload)
      return new Promise((resolve, reject) => {
        raspberryAxios.post(query, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            console.log('Error in API_GET query =' + query)
            console.log(error.response)
          })
      })
    }
  },
  modules: {
  }
})

<template>
  <div class="page-container">
    <div class="content-wrapper">
      <div class="running-multimedia-viewer">
        <table id="binding-before">
          <tr>
            <th colspan="3" style="text-align: center;">{{valueEveryWhere.binding.name}}</th>
          </tr>
          <tr>
            <th>X</th>
            <th>Y</th>
          </tr>
          <tr v-for="(row, index) in valueEveryWhere.binding.coordinates" :key="index">
            <td>{{row[0]}}</td>
            <td>{{row[1]}}</td>
          </tr>
        </table>
        <div></div>
        <!--
        <img src="../assets/images/svg/ski_symbol.svg">
        -->
        <table id="binding-before">
          <tr>
            <th colspan="3" style="text-align: center;">{{valueEveryWhere.binding.name}} (after)</th>
          </tr>
          <tr>
            <th>X</th>
            <th>Y</th>
            <th>Z</th>
          </tr>
          <tr v-for="(row, index) in localBinding.binding" :key="index">
            <td>{{row[0]}}</td>
            <td>{{row[1]}}</td>
            <td>{{row[2]}}</td>
          </tr>
        </table>
      </div>
      <ul style="overflow: scroll;">
        <li v-for="(row, index) in localBinding.g_code.split('\n')" :key="index">
          {{row}}
        </li>
      </ul>
      <div class="running-text">
        <span> The code is running...</span>
        <div style="font-size: 14px; color: blue">
          <span> Boot size(origin): {{valueEveryWhere.binding.size}}; </span>
          <span> Boot size: {{valueEveryWhere.length}}; </span>
          <span> Offset: {{valueEveryWhere.offset}}; </span>
          <span> Diameter: {{valueEveryWhere.diameter}}; </span>
          <span> Depth: {{valueEveryWhere.depth}}</span>
        </div>
      </div>
      <div class="running-action-button">
        <v-btn
          @click="handleMenuNavigationButton('/home')"
          color="var(--nav-button-flame-pea)"
        >
          <span> Home </span>
        </v-btn>
        <!--
        <v-btn
          @click="handleMenuNavigationButton('/home')"
          color="var(--nav-button-flame-pea)"
        >
          <span> Duet controller </span>
        </v-btn>
        -->
        <v-btn
          @click="() => {}"
          color="var(--nav-button-flame-pea)"
        >
          <span> STOP </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
// import crc32 from 'turbo-crc32/crc32'
// import crc32 from 'turbo-crc32/crc32'
export default {
  name: 'StartView',
  components: {
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    openDuetController: false,
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false,
    localBinding: {
      binding: null,
      g_code: null
    }
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
    const data = {
      size: parseFloat(this.valueEveryWhere.length),
      offset: this.valueEveryWhere.offset,
      diameter: this.valueEveryWhere.diameter,
      depth: this.valueEveryWhere.depth
    }
    // const query = `/binding/new/${this.valueEveryWhere.binding.id}`
    const query = `/binding/new/gcode/${this.valueEveryWhere.binding.id}`
    const payload = { query, data }
    console.log('runCode')
    console.log('payload')
    console.log(payload)
    this.POST_API_RASPBERRY(payload).then(
      resp => {
        console.log('POST_API_RASPBERRY - DATA')
        console.log(resp)
        this.localBinding = resp
      }
    )
  },
  computed: {
    ...mapState([
      'valueEveryWhere'
    ]),
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    numberRules () {
      return [
        (v) => (!isNaN(parseFloat(v)) && !!v && !v.toString().includes(',')) || 'Ska vara ifyllt och numeriskt!'
      ]
    },
    booleanRules () {
      return [
        (v) => this.booleanValues.includes(v) || 'This field is required !'
      ]
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'POST_API_RASPBERRY',
      'connect'
    ]),
    handleMenuNavigationButton (page) {
      this.$router.push(page)
    },
    async runCode (event) {
      console.log('RUN CODE...')
      console.log(event)
      const data = {
        size: parseFloat(this.valueEveryWhere.length),
        offset: this.valueEveryWhere.offset,
        diameter: this.valueEveryWhere.diameter,
        depth: this.valueEveryWhere.depth
      }
      // const query = `/binding/new/${this.valueEveryWhere.binding.id}`
      const query = `/binding/new/gcode/${this.valueEveryWhere.binding.id}`
      const payload = { query, data }
      console.log('runCode')
      console.log('payload')
      console.log(payload)
      this.POST_API_RASPBERRY(payload).then(
        resp => {
          console.log('POST_API_RASPBERRY - DATA')
          console.log(resp)
        }
      )
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}

</script>

<style scoped>

html, body {
  margin: 0;
  padding: 0;
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: white;
}

.content-wrapper {
  /*border: 2px solid rebeccapurple;*/
  padding: 0.5rem;
  width: 95%;
  height: 95%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}

.running-multimedia-viewer {
  /*border: 3px solid var(--flame-pea);*/
  border: 3px solid var(--ski-color);
  height: 70%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.running-multimedia-viewer img {
  height: 45vh;
}

.running-text {
  /*border: 2px solid greenyellow;*/
  height: 10%;
  font-size: 20px;
  font-weight: bold;
  color: var(--flame-pea);
  text-align: center;
}

.running-action-button {
  /*border: 2px solid orangered;*/
  height: 20%;
  display: flex;
  gap: 0.2rem;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.running-action-button .v-btn:not(.v-btn--round).v-size--default {
  border: 2px solid white;
  color: white;
  font-family: "Komika";
  background: var(--flame-pea);
  height: 35%;
  min-height: 30px;
}

/*--------------------------------------------*/
/*--------------------------------------------*/

.running-multimedia-viewer img {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#binding-before {
  font-family: "Komika", sans-serif;
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;
}
#binding-before td, #binding-before th {
  border: 1px solid #ddd;
  padding: 1px;
  text-align: center;
}
#binding-before tr:nth-child(even){background-color: #f2f2f2;}
#binding-before tr:hover {background-color: #ddd;}
#binding-before th {
  text-align: center;
  background-color: var(--flame-pea);
  color: white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .running-action-button {
    /*border: 2px solid orangered;*/
    height: 20%;
    display: flex;
    gap: 0.2rem;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
  }

  .running-action-button .v-btn:not(.v-btn--round).v-size--default {
    border: 2px solid white;
    color: white;
    font-family: "Komika";
    background: var(--flame-pea);
    height: 25%;
  }

  #binding-before {
    font-size: 10px;
  }
}
</style>

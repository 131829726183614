<template>
  <div class="undo-box-container">
    <div class="undo-box-title">
      {{ text }}
    </div>
    <div
      class="undo-box-button-container"
      @click="handleClickEvent"
    >
      <v-icon
        class="undo-box-button-icon"
        color="var(--flame-pea)"
      >
        mdi-arrow-u-left-top-bold
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoTextButton',
  props: {
    text: {
      type: String
    },
    view: {
      type: String
    }
  },
  methods: {
    handleClickEvent () {
      console.log('Change view to=', this.view)
      this.$emit('navigationTriggered', this.view)
    }
  }
}
</script>

<style>
.something {
  font-family: "Museo";
  border-color: var(--gravel);
  background-color: var(--hint-of-red);
}

.undo-box-container {
  /*border: 2px solid #adb056;*/
  margin: 1px;
  padding: 0.2rem;
  gap: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  width: 100%;
  height: 25%;
}

.undo-box-title, .undo-box-button-container {
  border: 2px solid var(--flame-pea);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.undo-box-title {
  font-family: "Komika";
  font-size: var(--font-size-m);
  /*
    font-size: 10px;
    border: 2px solid #00bbff;
  */
  color: var(--flame-pea);
  width: 90%;
  padding: 0.7rem;
  justify-content: flex-start;
}

.undo-box-button-container {
  /*
  border: 2px solid #ee00ff;
*/
  justify-content: center;
  width: 25%;
}

.undo-box-button-container:hover {
  cursor: pointer;
  background-color: var(--hint-of-red);
}

</style>

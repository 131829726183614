<template>
  <div class="page-container">
    <div class="content-wrapper">
      <div class="start-menu">
        <v-btn
          class="menu-btn"
          @click="startDrill">
          Start
        </v-btn>
        <v-btn
          class="menu-btn"
          @click="() => {}">
          Settings
        </v-btn>
        <v-btn
          class="menu-btn"
          @click="openDuetController=!openDuetController">
          Duet controller
        </v-btn>
        <v-btn
          class="menu-btn"
          @click="uploadView">
          Upload
        </v-btn>
      </div>
      <div class="power-off-button">
        <v-btn
          @click="poweroff"
          fab
          color="var(--nav-button-flame-pea)"
        >
          <v-icon
            color="white"
          >
            mdi-power
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="openDuetController"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="v-card-iframe-container">
        <v-system-bar
          color="var(--gravel)"
          height="50"
        >
          <span style="font-family: 'Komika'; color: white">Duet controller view</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="ma-2"
            @click="openDuetController=!openDuetController"
          >
            <v-icon color="white">mdi-minus</v-icon>
          </v-btn>
          <v-btn
            icon
            class="ma-2"
          >
            <v-icon color="var(--flame-pea)">mdi-checkbox-blank-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            class="ma-2"
            @click="openDuetController=!openDuetController"
          >
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-system-bar>
        <div class="iframe-container">
          <!--
          <iframe
            class="iframe-content"
            id="inlineFrameExample"
            title="Inline Frame Example"
            src="https://pythonbasics.org/flask-mongodb/">
          </iframe>
          -->
          <!--
  <iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width="500"
          height="600"
          class="iframe-content"
          src="http://192.168.1.207/">
  </iframe>
          -->
          <iframe
            class="iframe-content"
            id="duet-iframe-container"
            title="Duet controller"
            src="http://192.168.1.207">
          </iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
// import crc32 from 'turbo-crc32/crc32'
// import crc32 from 'turbo-crc32/crc32'
export default {
  name: 'StartView',
  components: {
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    openDuetController: false,
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
  },
  computed: {
    ...mapState([
      'loggingMsg'
    ]),
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    numberRules () {
      return [
        (v) => (!isNaN(parseFloat(v)) && !!v && !v.toString().includes(',')) || 'Ska vara ifyllt och numeriskt!'
      ]
    },
    booleanRules () {
      return [
        (v) => this.booleanValues.includes(v) || 'This field is required !'
      ]
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'GET_API_RASPBERRY',
      'connect'
    ]),
    startDrill () {
      console.log('Start Drill button clicked !')
      this.$router.push('/keyboard')
    },
    uploadView () {
      this.$router.push('/upload')
    },
    poweroff () {
      console.log('Powering off raspberry pi...')
      // this.$raspberryAPI.get('/status')
      this.GET_API_RASPBERRY('/shutdown')
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}

</script>

<style scoped>

html, body {
  margin: 0;
  padding: 0;
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: var(--gravel);
}

.content-wrapper {
  /*
  border: 2px solid rebeccapurple;
  padding: 0.5rem;
  */
  width: 60%;
  min-width: 200px;
}

.start-menu {
  /* border: 2px solid springgreen; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 0.5rem;
  padding: 0.2rem;
}

.menu-btn {
  min-height: 60px;
  min-width: 250px;
  width: 100%;
  border-radius: 10px;
}
/* version1: */
.start-menu .v-btn:not(.v-btn--round).v-size--default {
  border: 2px solid var(--flame-pea);
  color: var(--flame-pea);
  font-family: "Komika";
  background: white;
}
/* version2: */
.start-menu .v-btn:not(.v-btn--round).v-size--default {
  border: 2px solid white;
  color: white;
  font-family: "Komika";
  background: var(--flame-pea);
}

.power-off-button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.v-card-iframe-container {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.iframe-container {
  border: 3px solid var(--flame-pea);
  margin: 0.5rem;
  min-height: 300px;
  height: 95vh;
}
.iframe-content {
  width: 100%;
  height: 100%;
}

</style>

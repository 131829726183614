import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
/*
console.log('process.env.VUE_APP_MODE_PRODUCTION =', process.env.VUE_APP_MODE_PRODUCTION)
console.log('process.env.RASPBERRY_API_BASE =', process.env.VUE_APP_RASPBERRY_API_BASE)
console.log('process.env.RASPBERRY_API_BASE_PROD =', process.env.VUE_APP_RASPBERRY_API_BASE_PROD)
console.log('process.env.VUE_APP_DUET_CONTROLLER =', process.env.VUE_APP_DUET_CONTROLLER)
const duetControllerBase = process.env.VUE_APP_DUET_CONTROLLER
const raspberryBase = process.env.VUE_APP_MODE_PRODUCTION ? process.env.VUE_APP_RASPBERRY_API_BASE : process.env.VUE_APP_RASPBERRY_API_BASE_PROD
const duetControllerAxios = axios.create({
  baseURL: duetControllerBase
})
const raspberryAxios = axios.create({
  baseURL: raspberryBase // 'http://localhost:5000'
})
console.log('raspberryBase-----')
console.log(raspberryBase)
console.log('raspberryAxios-----')
console.log(raspberryAxios)
Vue.prototype.$raspberryAPI = raspberryAxios
Vue.prototype.$duetControllerAPI = duetControllerAxios

use this.$raspberryAPI.get(url, config).then(resp => {}) in code
use this.$duetControllerAPI.get(url, config).then(resp => {}) in code
 */
const duetControllerBase = process.env.VUE_APP_DUET_CONTROLLER
axios.defaults.baseURL = `${duetControllerBase}`

Vue.use(axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

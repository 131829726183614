<template>
  <div class="left-right" @click="handleClickEvent" id="lr_button">
    <!--<div class="text-lr" :style="getFontStyle">{{ text }}</div>-->
    <v-icon large color="var(--hint-of-red)" v-if="direction === 'LEFT'"> mdi-chevron-left </v-icon>
    <v-icon large color="var(--hint-of-red)" v-else-if="direction === 'RIGHT'"> mdi-chevron-right </v-icon>
  </div>
</template>

<script>
export default {
  name: 'LeftRight',
  props: {
    text: {
      type: String
    },
    direction: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClickEvent () {
      console.log('Child vbutton clicked=', this.direction)
      this.$emit('navigationTriggered', this.direction)
    },
    getFormatStyle (width, fsize, fweight) {
      return {
        width: `${width}px`
      }
    },
    getFontSizeStyle (fsize) {
      return {
        'font-size': `${fsize}px`
      }
    }
  },
  computed: {
    getFontStyle () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFontSizeStyle(40)
        case 'sm': return this.getFontSizeStyle(40)
        case 'md': return this.getFontSizeStyle(100)
        case 'lg': return this.getFontSizeStyle(110)
        case 'xl': return this.getFontSizeStyle(120)
        default:
          return this.getFontSizeStyle(100)
      }
    },
    getMyStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFormatStyle(50, 12, 400)
        case 'sm': return this.getFormatStyle(71, 25, 400)
        case 'md': return this.getFormatStyle(100, 30, 400)
        case 'lg': return this.getFormatStyle(131, 35, 400)
        case 'xl': return this.getFormatStyle(141, 36, 400)
        default:
          return this.getFormatStyle(111, 25, 400)
      }
    }
  }
}
</script>

<style>
.left-right {
  border-radius: 10px;
  background-color: var(--gravel);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  min-width: 5rem;
}

.left-right:hover {
  cursor: pointer;
  transform: scale(1.009);
  color: white;
  border-color: white;
  background-color: var(--flame-pea);
}

.text-lr {
  font-family: "Komika";
  /* font-size: var(--font-size-xl); */
  text-align: center;
  /*font-size: 60px;*/
}
</style>

<template>
  <div class="go-button" @click="handleClickEvent">
    <h1 class="text-go" :style="myStyles"> {{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'GoButton',
  props: {
    title: {
      type: String
    }
  },
  data () {
    return {
      height: 60,
      fontsize: 36,
      fontweight: 400,
      position: 'relative',
      letterspacing: 7,
      minwidth: 281,
      event: 'GO'
    }
  },
  methods: {
    handleClickEvent () {
      console.log('Change view to=')
      console.log('GO CLICKED !!!!!!!')
      this.$emit('goClickedEvent', this.event)
    },
    getFormatStyle (width, fsize, fweight, lspacing, minwidth) {
      return {
        width: `${width}px`,
        'font-size': `${fsize}px`,
        'font-weight': fweight,
        'letter-spacing': `${lspacing}px`,
        'min-width': `${minwidth}px`
      }
    }
  },
  computed: {
    myStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFormatStyle(80, 23, 400, 0, 50)
        case 'sm': return this.getFormatStyle(100, 80, 400, 0, 50)
        case 'md': return this.getFormatStyle(150, 50, 400, 0, 50)
        case 'lg': return this.getFormatStyle(200, 60, 400, 0, 50)
        case 'xl': return this.getFormatStyle(260, 70, 400, 0, 50)
        default:
          return this.getFormatStyle(200, 70, 400, 0, 50)
      }
    }
  }
}
</script>

<style>
.go-button{
  border: 1px none;
  border-radius: 20px;
  background-color: var(--flame-pea);
  box-shadow: 0px 4px 4px #00000040;
  height: 100%;
  min-width: 100%;
  /*min-width: 60px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.go-button:hover {
  cursor: pointer;
}
.text-go{
  font-family: "Komika";
  color: var(--hint-of-red);
  text-align: center;
}
</style>

<template>
  <v-card elevation="5" class="card-start-page rounded-xl">
    <div class="btn-actions centered-element" id="buttons-main-page">
      <v-btn color="orange" @click="connectServer">
        Connect
      </v-btn>
      <v-btn class="btn-active" @click="openFileDialog">
        Upload
      </v-btn>
      <v-btn class="btn-active" @click="backHome">
        Back
      </v-btn>
    </div>
    <input hidden type="file" ref="fileInput" :accept="accept" @change="fileSelected">
  </v-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
// import crc32 from 'turbo-crc32/crc32'
// import crc32 from 'turbo-crc32/crc32'
export default {
  name: 'HomeView',
  components: {
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false,
    target: 'gcodes',
    settings: {
      crcUploads: true
    },
    directories: {
      filaments: '0:/filaments',
      firmware: '0:/sys',
      gCodes: '0:/gcodes',
      macros: '0:/macros',
      menu: '0:/menu',
      scans: '0:/scans',
      system: '0:/sys',
      web: '0:/www'
    }
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
  },
  computed: {
    ...mapState([
      'loggingMsg'
    ]),
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    numberRules () {
      return [
        (v) => (!isNaN(parseFloat(v)) && !!v && !v.toString().includes(',')) || 'Ska vara ifyllt och numeriskt!'
      ]
    },
    booleanRules () {
      return [
        (v) => this.booleanValues.includes(v) || 'This field is required !'
      ]
    },
    accept () {
      switch (this.target) {
        case 'gcodes': return '.g,.gcode,.gc,.gco,.nc,.ngc,.tap'
        case 'start': return '.g,.gcode,.gc,.gco,.nc,.ngc,.tap,.zip'
        case 'macros': return '*'
        case 'filaments': return '.zip'
        case 'firmware': return '.zip,.bin,.uf2'
        case 'menu': return '*'
        // case 'system': return '.zip,.bin,.uf2,.json,.g,.csv,.xml' + (this.connectorType === 'rest' ? ',.deb' : '')
        case 'web': return '.zip,.csv,.json,.htm,.html,.ico,.xml,.css,.map,.js,.ttf,.eot,.svg,.woff,.woff2,.jpeg,.jpg,.png,.gz'
        case 'plugin': return '.zip'
        case 'update': return '.zip,.bin,.uf2'
      }
      return undefined
    },
    destinationDirectory () {
      /*
        if (this.directory) {
          return this.directory;
        }
      */
      switch (this.target) {
        case 'gcodes': return this.directories.gCodes
        case 'start': return this.directories.gCodes
        case 'firmware': return this.directories.firmware
        case 'macros': return this.directories.macros
        case 'filaments': return this.directories.filaments
        case 'menu': return this.directories.menu
        case 'system': return this.directories.system
        case 'web': return this.directories.web
          // plugin is not applicable
        case 'update': return this.directories.firmware
      }
      return undefined
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'upload',
      'connect',
      'runGCode'
    ]),
    pathCombiner () {
      let result = ''
      Array.from(arguments).forEach(function (arg) {
        if (arg.startsWith('/') || /(\d)+:.*/.test(arg)) {
          if (arg.endsWith('/')) {
            result = arg.substring(0, arg.length - 1)
          } else {
            result = arg
          }
        } else {
          if (result !== '') {
            result += '/'
          }
          if (arg.endsWith('/')) {
            result += arg.substring(0, arg.length - 1)
          } else {
            result += arg
          }
        }
      })
      return result
    },
    timeToStr (time) {
      // Returns an ISO-like datetime string like "2016-10-24T15:39:09"
      // Cannot use toISOString() here because it doesn't output the localtime
      let result = ''
      result += time.getFullYear() + '-'
      result += (time.getMonth() + 1) + '-'
      result += time.getDate() + 'T'
      result += time.getHours() + ':'
      result += time.getMinutes() + ':'
      result += time.getSeconds()
      return result
    },
    strToTime (str) {
      const results = /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)/.exec(str)
      if (results != null) {
        const date = new Date()
        date.setFullYear(results[1])
        date.setMonth(results[2] - 1)
        date.setDate(results[3])
        date.setHours(results[4])
        date.setMinutes(results[5])
        date.setSeconds(results[6])
        return date
      }
      return undefined
    },
    openFileDialog () {
      this.$refs.fileInput.click()
    },
    backHome () {
      this.$router.push('/')
    },
    async fileSelected (e) {
      await this.handleFileUpload(e.target.files)
      e.target.value = ''
    },
    async handleFileUpload (files, zipName, startTime) {
      const content = files[0]
      const filename = this.pathCombiner(this.destinationDirectory, content.name)
      console.log('content')
      console.log(content)
      console.log('filename')
      console.log(filename)
      console.log('this.target')
      console.log(this.target)
      content.filename = filename
      console.log('content instanceof (Blob)')
      console.log(content instanceof (Blob))
      const data = (content instanceof (Blob)) ? content : new Blob([content])
      const params = {
        name: filename,
        time: this.timeToStr(new Date()),
        crc32: 'af9b31f2'
      }
      // Check if the CRC32 checksum is required
      /*
      if (this.settings.crcUploads) {
        const checksum = await new Promise(function (resolve) {
          const fileReader = new FileReader()
          fileReader.onload = function (e) {
            const result = crc32(e.target.result)
            resolve(result)
          }
          fileReader.readAsArrayBuffer(payload)
        })
        params.crc32 = checksum.toString(16)
      }
       */
      console.log('Params')
      console.log(params)
      console.log('data')
      console.log(data)

      let query = 'http://192.168.1.207/rr_upload'
      if (params) {
        let hadParam = false
        for (const key in params) {
          query += (hadParam ? '&' : '?') + key + '=' + encodeURIComponent(params[key])
          hadParam = true
        }
      }
      console.log('query')
      console.log(query)
      const payload = { data, query }
      await this.upload(payload)
      // await this.upload({ filename: files[0].filename, content: files[0], showSuccess: !zipName })
    },
    uploadGFile () {
      console.log('Uploaded...')
    },
    connectServer () {
      console.log('Connect...')
      // upLoad.dispath()
      this.connect()
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}

</script>

<style scoped>
.card-start-page {
  height: 100%;
  width: 100%;
  background-color: #ECECED;
  border: #B0B1B3 5px solid;
  /* background-color: #ECECED;*/
}

.btn-active {
  color: #F56824;
  text-decoration: none;
  background-color: red;
}

.btn-actions {
  /* background-color: rgb(29, 100, 152); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.centered-element {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>

<template>
  <div class="main-container">
    <main>
      <left-right-component class="left-navigation" text="<" direction="LEFT" @navigationTriggered="navigatePage"/>
      <div class="main-content">
        <div class="flex-col-diameter">
          <div class="go-button-container">
            <go-button title="GO" @goClickedEvent="runCode" />
          </div>
        </div>
        <div class="flex-col-depth">
          <div class="option-list-container">
            <go-text-button :text="valueEveryWhere.binding.name" view="BINDING" @navigationTriggered="navigatePage"/>
            <go-text-button :text="valueEveryWhere.length + ' mm'" view="SOLE" @navigationTriggered="navigatePage"/>
            <go-text-button :text="valueEveryWhere.offset.toString() + ' mm'" view="OFFSET" @navigationTriggered="navigatePage"/>
            <go-text-button :text="valueEveryWhere.diameter + 'x' + valueEveryWhere.depth + ' mm'" view="DRILL" @navigationTriggered="navigatePage" />
          </div>
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="navigation-footer-button-large">
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/home')">
          <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
          <img src="../assets/images/svg/home_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
          <img src="../assets/images/svg/binding_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
          <img src="../assets/images/svg/boot_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
          <img src="../assets/images/svg/solelength_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
          <img src="../assets/images/svg/go_symbol.svg">
        </v-btn>
      </div>
      <div class="navigation-footer-button-small">
        <!--
        <left-right-component class="left-navigation-bottom" text="<" direction="LEFT" @navigationTriggered="navigatePage"/>
        -->
        <v-speed-dial
          v-model="menuButtonFab.fab"
          :top="menuButtonFab.top"
          :bottom="menuButtonFab.bottom"
          :right="menuButtonFab.right"
          :left="menuButtonFab.left"
          :direction="menuButtonFab.direction"
          :open-on-hover="menuButtonFab.hover"
          :transition="menuButtonFab.transition"
          class="button-speed-dial"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuButtonFab.fab"
              color="blue darken-2"
              dark
              fab
              small
            >
              <v-icon v-if="menuButtonFab.fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                <!-- mdi-account-circle -->
                mdi-cog-outline
              </v-icon>
            </v-btn>
          </template>
          <v-btn color="var(--nav-button-grey)" @click="handleMenuNavigationButton('/setting')">
            <img src="../assets/images/svg/setting_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/')">
            <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
            <img src="../assets/images/svg/home_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
            <img src="../assets/images/svg/binding_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
            <img src="../assets/images/svg/boot_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
            <img src="../assets/images/svg/solelength_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
            <img src="../assets/images/svg/go_symbol.svg">
          </v-btn>
        </v-speed-dial>
        <!--
        <left-right-component class="right-navigation-bottom" text=">" direction="RIGHT" @navigationTriggered="navigatePage"/>
        -->
      </div>
    </footer>
  </div>
</template>

<script>
/*
import ButtonNumberOperator from '@/components/ButtonNumberOperator'
import NumericalKeyboard from '@/components/NumericalKeyboard'
import NavigationButton from '@/components/NavigationButton'
*/
import LeftRightComponent from '@/components/LeftRightComponent.vue'
import { mapActions, mapState } from 'vuex'
import GoTextButton from '@/components/GoTextButton'
import GoButton from '@/components/GoButton'
export default {
  name: 'SoleLength',
  components: {
    LeftRightComponent,
    GoTextButton,
    GoButton
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    leftIcon: '<',
    rightIcon: '>',
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false,
    keyboardInputValue: 0,
    menuButtonFab: {
      direction: 'top', // top, right, left, bottom
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      /*
      top: false,
      right: true,
      bottom: true,
      left: false,
       */
      transition: 'slide-y-reverse-transition'
    },
    localBinding: {
      binding: null,
      g_code: null
    }
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
    const data = {
      size: parseFloat(this.valueEveryWhere.length),
      offset: this.valueEveryWhere.offset,
      diameter: this.valueEveryWhere.diameter,
      depth: this.valueEveryWhere.depth
    }
    // const query = `/binding/new/${this.valueEveryWhere.binding.id}`
    const query = `/binding/new/gcode/${this.valueEveryWhere.binding.id}`
    const payload = { query, data }
    console.log('runCode')
    console.log('payload')
    console.log(payload)
    this.POST_API_RASPBERRY(payload).then(
      resp => {
        console.log('POST_API_RASPBERRY - DATA')
        console.log(resp)
        this.localBinding = resp
      }
    )
  },
  computed: {
    ...mapState([
      'valueEveryWhere'
    ]),
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    dynamicFontStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFontSizeStyle(20)
        case 'sm': return this.getFontSizeStyle(25)
        case 'md': return this.getFontSizeStyle(30)
        case 'lg': return this.getFontSizeStyle(35)
        case 'xl': return this.getFontSizeStyle(45)
        default:
          return this.getFontSizeStyle(30)
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'POST_API_RASPBERRY',
      'sendGCode',
      'sendGCode2',
      'connect'
    ]),
    handleMenuNavigationButton (page) {
      this.$router.push(page)
    },
    async getHome () {
      return await this.sendGCode({
        gcode: encodeURIComponent('G92 X0 Y0 Z0')
      })
    },
    async runCode (event) {
      console.log('RUN CODE...')
      console.log(event)
      const data = {
        size: parseFloat(this.valueEveryWhere.length),
        offset: this.valueEveryWhere.offset,
        diameter: this.valueEveryWhere.diameter,
        depth: this.valueEveryWhere.depth
      }
      // const query = `/binding/new/${this.valueEveryWhere.binding.id}`
      const query = `/binding/new/gcode/${this.valueEveryWhere.binding.id}`
      const payload = { query, data }
      console.log('runCode')
      console.log('payload')
      console.log(payload)
      this.POST_API_RASPBERRY(payload).then(
        resp => {
          console.log('POST_API_RASPBERRY - DATA')
          console.log(resp)
        }
      )
      const run = true
      await this.$router.push('/coderun')
      if (run) {
        console.log('runCode')
        console.log('Event=', event)
        const commands = [
          'G1 X50 F1000',
          'G1 Y20 F1000',
          'G1 X0 F1000',
          'G1 Y0 F1000',
          'M18']
        // "M98 P'start.g'"
        // const simpleCommand = 'M98 P"start.g"'
        // commands = this.localBinding.g_code.split('\n')
        console.log('RIGHT')
        /*
        await this.sendGCode2({
          gcode: encodeURIComponent(simpleCommand)
        })
        */
        await this.sendGCode2({
          gcode: encodeURIComponent('G92 X0 Y0')
        }).then(
          async _ => {
            for (let i = 0; i < commands.length; i++) {
              await this.sendGCode2({
                gcode: encodeURIComponent(commands[i])
              })
            }
          }
        )
      }
      // this.$router.push('/RIGTH')
    },
    async navigatePage (dir) {
      console.log('***************')
      console.log('navigatePage...', dir)
      switch (dir) {
        case 'BINDING':
          console.log('BINDING')
          this.$router.push('/keyboard')
          break
        case 'SOLE':
          console.log('SOLE')
          this.$router.push('/sole_length')
          break
        case 'OFFSET':
          console.log('OFFSET')
          this.$router.push('/motor_diam')
          break
        case 'DRILL':
          console.log('DRILL')
          this.$router.push('/motor_diam')
          break
        case 'LEFT':
          console.log('LEFT')
          this.$router.push('/motor_diam')
          break
        default:
          console.log('default')
      }
    },
    getFontSizeStyle (fsize) {
      return {
        'font-size': `${fsize}px`
      }
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}
</script>

<style scoped>

.option-list-container, .go-button-container {
  height: 100%;
  width: 100%;
  /*border: 2px solid deepskyblue;*/
}
.option-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.button-speed-dial {
  /*
  position: fixed;
  top: 50%;
  left: 5px;
   */
}

.header {
  /*border: 3px solid blue;*/
  border-bottom: 1px solid var(--flame-pea);
  position: fixed;
  height: 7vh;
  right:0;
  left: 0;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer {
  /*border: 3px solid red;*/
  position: fixed;
  height: 11vh;
  right:0;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.main-content {
  /*border: 2px solid darkgoldenrod;*/
  margin-left: 23vh;
  margin-right: 4vh;
  margin-top: 8vh;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.flex-col-diameter {
  /*border: 2px solid #7b02fc;*/
  width: 60%;
  height: 100%;
}

.flex-col-depth {
  /*border: 2px solid #02fc23;*/
  width: 60%;
  height: 100%;
}

.text-flame-pea{
  color: var(--flame-pea);
  font-family: "Komika";
  font-size: var(--font-size-m);
  height: 53px;
  min-height: 30px;
  text-align: center;
}

.text-gravel{
  color: var(--gravel);
  font-family: "Komika";
  font-size: var(--font-size-m);
  height: 53px;
  min-height: 30px;
  text-align: center;
}

.left-navigation {
  margin: 5px;
  height: 79vh;
  width: 10vh;
  position: fixed;
  top:7.7vh;
  left: 0;
  bottom: 15vh;
}

.right-navigation {
  /* border: 2px solid darkorange;*/
  margin: 5px;
  height: 70vh;
  width: 10vh;
  position: fixed;
  top:15vh;
  right: 0;
  bottom: 15vh;
}

.navigation-footer-button-large {
  display: flex;
  /*border: 2px solid greenyellow;*/
  gap: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .left-navigation {
    display: none;
  }
  .right-navigation {
    display: none;
  }
  .main-content {
    margin-left: 1vh;
    margin-right: 1vh;
  }
  .navigation-footer-button-large {
    display: none;
  }
  .navigation-footer-button-small {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .navigation-footer-button-small .left-navigation-bottom {
    width: 40.99%;
    height: 10vh;
    border-radius: 0;
  }
  .navigation-footer-button-small .right-navigation-bottom {
    width: 40.99%;
    height: 10vh;
    border-radius: 0;
    opacity: 0.5;
    cursor: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navigation-footer-button-small {
    display: none;
  }
}

</style>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
</style>

<template>
  <v-app>
    <!--
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
  -->

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
/*
http://192.168.1.207/rr_reply
http://192.168.1.207/rr_reply
http://192.168.1.207/rr_model?flags=d99fn
http://192.168.1.207/rr_model?flags=d99fn
http://192.168.1.207/machine/connect?password=reprap
http://192.168.1.207/machine/connect?password=reprap
*/

export default {
  name: 'App',
  components: {
  },

  data: () => ({
    //
  })
}
</script>

<style>
@font-face {
  font-family: "Komika";
  src: local("Komika"), url('assets/fonts/KOMIKAX_.ttf') format("truetype");
}

@font-face {
  font-family: "Museo";
  src: local("Museo"),
  url('assets/fonts/MuseoSans_300-webfont.ttf') format("truetype");
}

:root{
  --black: rgba(0,0,0,1);
  --gravel: rgba(70,71,78,1);
  --flame-pea: rgba(220,103,46,1);
  --hint-of-red: rgba(249,249,249);
  --color-lr-hover: rgb(199, 197, 197);
  --nav-button-flame-pea: #EB6726;
  --nav-button-grey: rgb(69, 73, 78);
  --ski-color: rgb(192, 175, 209);
  --font-size-xl: 45px;
  --font-size-l: 36px;
  --font-size-m: 25px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none;
}
</style>

<template>
  <div class="main-container">
    <main>
      <left-right-component class="left-navigation" text="<" direction="LEFT" @navigationTriggered="navigatePage"/>
      <div class="main-content">
        <div class="flex-col-diameter">
          <!--<div class="text-flame-pea" :style="dynamicFontStyles"> LENGTH </div>-->
          <div class="button-group-container">
            <input
              type="text"
              id="inputSoleLength"
              class="input-sole-length"
              name="inputSoleLength"
              placeholder="Enter sole length"
              v-model="keyboardInputValue"
              style="height: 10%"
            >
            <num-pad-component @numberEntered="handleNumericalKeyInput" />
          </div>
        </div>
        <div class="flex-col-depth">
          <div class="button-group-container">
            <div class="preset-button-container">
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('265')">265</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('275')">275</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('285')">285</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('295')">295</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('305')">305</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('315')">315</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('325')">325</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('276')">276</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('286')">286</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('296')">296</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('306')">306</v-btn>
              <v-btn elevation="0" class="preset-button" @click="handlePresetButton('316')">316</v-btn>
            </div>
          </div>
        </div>
      </div>
      <left-right-component class="right-navigation" text=">" direction="RIGHT" @navigationTriggered="navigatePage"/>
    </main>
    <footer class="footer">
      <div class="navigation-footer-button-large">
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/home')">
          <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
          <img src="../assets/images/svg/home_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
          <img src="../assets/images/svg/binding_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
          <img src="../assets/images/svg/boot_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
          <img src="../assets/images/svg/solelength_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
          <img src="../assets/images/svg/go_symbol.svg">
        </v-btn>
      </div>
      <div class="navigation-footer-button-small">
        <left-right-component class="left-navigation-bottom" text="<" direction="LEFT" @navigationTriggered="navigatePage"/>
        <v-speed-dial
          v-model="menuButtonFab.fab"
          :top="menuButtonFab.top"
          :bottom="menuButtonFab.bottom"
          :right="menuButtonFab.right"
          :left="menuButtonFab.left"
          :direction="menuButtonFab.direction"
          :open-on-hover="menuButtonFab.hover"
          :transition="menuButtonFab.transition"
          class="button-speed-dial"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuButtonFab.fab"
              color="blue darken-2"
              dark
              fab
              small
            >
              <v-icon v-if="menuButtonFab.fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                <!-- mdi-account-circle -->
                mdi-cog-outline
              </v-icon>
            </v-btn>
          </template>
          <v-btn color="var(--nav-button-grey)" @click="handleMenuNavigationButton('/setting')">
            <img src="../assets/images/svg/setting_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/')">
            <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
            <img src="../assets/images/svg/home_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
            <img src="../assets/images/svg/binding_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
            <img src="../assets/images/svg/boot_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
            <img src="../assets/images/svg/solelength_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
            <img src="../assets/images/svg/go_symbol.svg">
          </v-btn>
        </v-speed-dial>
        <left-right-component class="right-navigation-bottom" text=">" direction="RIGHT" @navigationTriggered="navigatePage"/>
      </div>
    </footer>
  </div>
</template>

<script>
import LeftRightComponent from '@/components/LeftRightComponent.vue'
// import ButtonGroup from '@/components/ButtonGroup'
import NumPadComponent from '@/components/NumPadComponent.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SoleLength',
  components: {
    LeftRightComponent,
    // ButtonGroup,
    NumPadComponent
    // ButtonNumberOperator,
    // NumericalKeyboard,
    // NavigationButton
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    leftIcon: '<',
    rightIcon: '>',
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false,
    menuButtonFab: {
      direction: 'top', // top, right, left, bottom
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      /*
      top: false,
      right: true,
      bottom: true,
      left: false,
       */
      transition: 'slide-y-reverse-transition'
    },
    diameterRange: [
      true,
      false
    ],
    lengthRange: [
      false,
      true
    ],
    slider: {
      label: 'color',
      thumbcolor: 'green darken-3',
      offsetVal: 0,
      color1: 'red',
      color2: 'blue',
      range: {
        min: -10,
        max: 10
      }
    },
    keyboardInputValue: null,
    offsetInput: 0
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
  },
  computed: {
    ...mapState([
      'valueEveryWhere'
    ]),
    trackColor () {
      if (this.slider.offsetVal < 0) return 'red'
      if (this.slider.offsetVal > 0) return 'blue'
      return 'grey'
    },
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    numberRules () {
      return [
        (v) => (!isNaN(parseFloat(v)) && !!v && !v.toString().includes(',')) || 'Ska vara ifyllt och numeriskt!'
      ]
    },
    booleanRules () {
      return [
        (v) => this.booleanValues.includes(v) || 'This field is required !'
      ]
    },
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
        default:
          return 500
      }
    },
    getBreakPointName () {
      return this.$vuetify.breakpoint.name
    },
    dynamicFontStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFontSizeStyle(16)
        case 'sm': return this.getFontSizeStyle(18)
        case 'md': return this.getFontSizeStyle(27)
        case 'lg': return this.getFontSizeStyle(35)
        case 'xl': return this.getFontSizeStyle(35)
        default:
          return this.getFontSizeStyle(30)
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'CHANGE_LENGTH'
      // 'CHANGE_OFFSET'
    ]),
    handleMenuNavigationButton (page) {
      this.$router.push(page)
    },
    handlePresetButton (e) {
      this.keyboardInputValue = e
    },
    handleNumericalKeyInput (e) {
      console.log('========= NUMERICAL KEYBOARD ======')
      console.log('CHILD ACTION: Enter a numerical value')
      console.log('Parent GOT: ')
      console.log(e)
      console.log('----------------------------')
      // console.log(this.keyboardInputValue.toString() + e.toString())
      // console.log(parseInt(this.keyboardInputValue.toString() + e.toString()))
      // this.keyboardInputValue = parseFloat(this.keyboardInputValue.toString() + e.toString())
      switch (e) {
        case 'DOT':
          this.keyboardInputValue = this.keyboardInputValue.toString() + '.'
          break
        case 'UNDO':
          this.keyboardInputValue = this.keyboardInputValue.toString().substring(0, this.keyboardInputValue.length - 1)
          break
        default:
          this.keyboardInputValue = this.keyboardInputValue === null ? '' : this.keyboardInputValue
          this.keyboardInputValue = this.keyboardInputValue.toString() + e.toString()
      }
    },
    navigatePage (dir) {
      console.log('***************')
      console.log('naviga tePage...', dir)
      switch (dir) {
        case 'LEFT':
          console.log('LEFT')
          this.$router.push('/keyboard')
          break
        case 'RIGHT':
          console.log('RIGHT')
          this.CHANGE_LENGTH(this.keyboardInputValue)
          this.$router.push('/motor_diam')
          break
        default:
          console.log('default')
      }
    },
    getFontSizeStyle (fsize) {
      return {
        'font-size': `${fsize}px`
      }
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
}

.button-speed-dial {
  /*
  position: fixed;
  top: 50%;
  left: 5px;
   */
}

.header {
  /* border: 3px solid blue;*/
  border-bottom: 1px solid var(--flame-pea);
  position: fixed;
  height: 7vh;
  right:0;
  left: 0;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer {
  /*border: 3px solid red;*/
  position: fixed;
  height: 11vh;
  right:0;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.main-content {
  /*border: 2px solid darkgoldenrod;*/
  margin-left: 23vh;
  margin-right: 23vh;
  margin-top: 8vh;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.15rem;
}

.button-group-container {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 0.2rem;
  height: 100%;
}

.flex-col-diameter {
  /*border: 2px solid #7b02fc;*/
  width: 100%;
  height: 100%;
}

.flex-col-depth {
  /*border: 2px solid #02fc23;*/
  width: 100%;
  height: 100%;
}

.preset-button-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  padding: 0.2rem;
}

.preset-button-container .v-btn:not(.v-btn--round).v-size--default{
  height: 100%;
}

.offset-container {
  /*border: 2px solid #3002fc;*/
  width: 100%;
  /*height: 60%;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.offset-container-element {
  /*border: 2px solid #fc0272;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1rem;
  /*
  display: flex;
  flex-direction: column;
  padding: 0.1rem;
  gap: 2rem;
   */
}

.preset-button {
  width: 100%;
  height: 100%;
  border: 2px solid var(--flame-pea);
  border-radius: 10px;
  background-color: var(--hint-of-red);
  font-family: "Komika";
  font-size: var(--font-size-m);
  color:var(--flame-pea);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.text-flame-pea{
  color: var(--flame-pea);
  font-family: "Komika";
  font-size: var(--font-size-m);
  min-height: 30px;
  text-align: center;
}

.text-gravel{
  color: var(--gravel);
  font-family: "Komika";
  font-size: var(--font-size-m);
  min-height: 30px;
  text-align: center;
}

.left-navigation {
  margin: 5px;
  height: 79vh;
  width: 10vh;
  position: fixed;
  top:7.7vh;
  left: 0;
  bottom: 15vh;
}

.right-navigation {
  /* border: 2px solid darkorange;*/
  margin: 5px;
  height: 79vh;
  width: 10vh;
  position: fixed;
  top:7.7vh;
  right: 0;
  bottom: 15vh;
}
.navigation-footer-button-large {
  display: flex;
  /*border: 2px solid greenyellow;*/
  gap: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  width: 100%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .left-navigation {
    display: none;
  }
  .right-navigation {
    display: none;
  }
  .main-content {
    margin-left: 1vh;
    margin-right: 1vh;
  }
  .navigation-footer-button-large {
    display: none;
  }
  .input-offset {
    height: 12%;
  }
  .offset-container {
    /* border: 2px solid #3002fc;*/
    width: 100%;
    height: 100%;
  }
  .offset-container-element {
    gap: 0;
  }
  .navigation-footer-button-small {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .navigation-footer-button-small .left-navigation-bottom {
    width: 40.99%;
    height: 10vh;
    border-radius: 0;
  }
  .navigation-footer-button-small .right-navigation-bottom {
    width: 40.99%;
    height: 10vh;
    border-radius: 0;
  }

  .preset-button-container {
    border: 2px solid #fc6202;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .preset-button-container .v-btn:not(.v-btn--round).v-size--default{
    height: 22%;
  }
  .v-slider--vertical {
    min-height: 146px;
    /* margin-top: 12px; */
    /* margin-bottom: 12px; */
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navigation-footer-button-small {
    display: none;
  }
}

</style>

<style scoped lang="scss">
%input-style {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 5px;
  box-sizing: border-box;
  -moz-appearance: textfield;
  font-family: "Komika";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=text].input-sole-length {
  border: 2px solid var(--gravel);
  font-family: "Museo";
  min-height: 3rem;
  @extend %input-style;
}

input[type=number].input-offset {
  @extend %input-style;
  height: 30%;
  text-align: center;
  border: 2px solid var(--gravel);
}
</style>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
</style>

<template>
  <div class="main-container">
    <main>
      <!--
      <p>SELECTED : {{favoriteBinding.selected}}</p>
      <p>DATA : {{favoriteBinding.data}}</p>
      -->
      <div v-if="getBreakPointName === 'xs'" class="main-content">
        <div class="main-content-element-mobile">
          <div class="input-container">
            <input
              type="text"
              id="inputSoleLength"
              class="input-sole-length"
              name="inputSoleLength"
              v-model="keyboardInputValue"
              placeholder="Enter type of binding..."
              style="height: 10%"
            >
          </div>
          <div class="favorite-button-container">
            <v-btn
              class="favorite-button"
              v-for="(item, index) in favoriteBinding.selected"
              :key="index"
              @click="handlePresetButton(item.name)"
            >
              {{item.name}}
            </v-btn>
            <!--
            <v-btn class="favorite-button" @click="handlePresetButton('marker b2')">
              <span>marker b748</span>
            </v-btn>
            <v-btn class="favorite-button" @click="handlePresetButton('marker p36')">
              <span>marker b748</span>
            </v-btn>
            <v-btn class="favorite-button" @click="handlePresetButton('marker k56')">
              <span>marker b748</span>
            </v-btn>
            <v-btn class="favorite-button" @click="handlePresetButton('marker c55')">
              <span>marker b748</span>
            </v-btn>
            -->
          </div>
          <v-list dense class="v-list-container">
            <template v-for="(item, index) in manufacturersListVisible">
              <v-list-item :key="index" :style="item.active? 'background-color: #f8faff': ''">
                <v-list-item-content>
                  <v-list-item-title class="text--primary font-family='Museo'" v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    @click="selectedManufacturer(item)"
                    fab
                    small
                    x-small
                    color="var(--flame-pea)"
                  >
                    <v-icon
                      color="white"
                      small
                    >
                      mdi-check-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-icon
                    :color="item.active? 'var(--flame-pea)':'grey lighten-1'"
                    @click="handleBindingListItemClicked(item)"
                  >
                    {{item.active? 'mdi-star': 'mdi-star-outline'}}
                  </v-icon>
                  <!-- color="yellow darken-3" -->
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < manufacturersListVisible.length - 1"
                :key="'divider-'+index"
              ></v-divider>
            </template>
          </v-list>
        </div>
      </div>
      <div v-else class="main-content">
        <div class="flex-col-diameter">
          <input
            type="text"
            id="inputSoleLength"
            class="input-sole-length"
            name="inputSoleLength"
            v-model="keyboardInputValue"
            placeholder="Enter type of binding..."
            style="height: 10%"
          >
          <div class="keyboard-and-input-container">
            <div class="favorite-button-container">
              <v-btn
                class="favorite-button"
                v-for="(item, index) in favoriteBinding.selected"
                :key="index"
                @click="handlePresetButton(item.name)"
              >
                {{item.name}}
              </v-btn>
              <!--
              <v-btn class="favorite-button" @click="handlePresetButton('marker b2')">
                <span>marker b748</span>
              </v-btn>
              <v-btn class="favorite-button" @click="handlePresetButton('marker p36')">
                <span>marker b748</span>
              </v-btn>
              <v-btn class="favorite-button" @click="handlePresetButton('marker k56')">
                <span>marker b748</span>
              </v-btn>
              <v-btn class="favorite-button" @click="handlePresetButton('marker c55')">
                <span>marker b748</span>
              </v-btn>
              -->
            </div>
            <key-board-component
              @characterEntered="showCharacter"
              class="keyboard-content"
              :letter-activity-table="letterActivity"
            >
            </key-board-component>
          </div>
        </div>
        <div class="flex-col-depth">
          <v-list dense class="v-list-container">
            <template v-for="(item, index) in manufacturersListVisible">
              <v-list-item :key="index" :style="item.active? 'background-color: #f8faff': ''">
                <v-list-item-content>
                  <v-list-item-title class="text--primary font-family:'Museo' " v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    @click="selectedManufacturer(item)"
                    fab
                    x-small
                    color="var(--flame-pea)"
                  >
                    <v-icon
                      color="white"
                      small
                    >
                      mdi-check-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-icon
                    :color="item.active? 'var(--flame-pea)':'grey lighten-1'"
                    @click="handleBindingListItemClicked(item)"
                  >
                    {{item.active? 'mdi-star': 'mdi-star-outline'}}
                  </v-icon>
                  <!-- color="yellow darken-3" -->
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < manufacturersListVisible.length - 1"
                :key="'divider-'+index"
              ></v-divider>
            </template>
          </v-list>
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="navigation-footer-button-large" v-if="getBreakPointName!=='xs'">
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/home')">
          <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
          <img src="../assets/images/svg/home_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
          <img src="../assets/images/svg/binding_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
          <img src="../assets/images/svg/boot_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
          <img src="../assets/images/svg/solelength_symbol.svg">
        </v-btn>
        <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
          <img src="../assets/images/svg/go_symbol.svg">
        </v-btn>
      </div>
      <div v-else class="navigation-footer-button-small">
        <v-speed-dial
          v-model="menuButtonFab.fab"
          :top="menuButtonFab.top"
          :bottom="menuButtonFab.bottom"
          :right="menuButtonFab.right"
          :left="menuButtonFab.left"
          :direction="menuButtonFab.direction"
          :open-on-hover="menuButtonFab.hover"
          :transition="menuButtonFab.transition"
          class="button-speed-dial"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuButtonFab.fab"
              color="blue darken-2"
              dark
              fab
              small
            >
              <v-icon v-if="menuButtonFab.fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                <!-- mdi-account-circle -->
                mdi-cog-outline
              </v-icon>
            </v-btn>
          </template>
          <v-btn color="var(--nav-button-grey)" @click="handleMenuNavigationButton('/setting')">
            <img src="../assets/images/svg/setting_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/')">
            <!-- <img style="height: 36px; border: 1px solid red" src="../assets/images/svg/home_symbol.svg"> -->
            <img src="../assets/images/svg/home_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/keyboard')">
            <img src="../assets/images/svg/binding_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/sole_length')">
            <img src="../assets/images/svg/boot_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/motor_diam')">
            <img src="../assets/images/svg/solelength_symbol.svg">
          </v-btn>
          <v-btn color="var(--nav-button-flame-pea)" @click="handleMenuNavigationButton('/go')">
            <img src="../assets/images/svg/go_symbol.svg">
          </v-btn>
        </v-speed-dial>
      </div>
    </footer>
  </div>
</template>

<script>
// import TitleComponent from '@/components/TitleComponent.vue'
// import ButtonGroup from '@/components/ButtonGroup'
import keyBoardComponent from '@/components/KeyBoardComponent'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SoleLength',
  components: {
    // TitleComponent,
    keyBoardComponent
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    source: ''
  },
  // TODO : make all this data variables dynamical through read from database or Api request in state, actions, mutations
  data: () => ({
    leftIcon: '<',
    rightIcon: '>',
    currentStepPage: 1, // TODO: BREAK_POINT_URGENT
    valid: true,
    hasSaved: false,
    menuButtonFab: {
      direction: 'top', // top, right, left, bottom
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      /*
      top: false,
      right: true,
      bottom: true,
      left: false,
       */
      transition: 'slide-y-reverse-transition'
    },
    diameterRange: [
      true,
      false
    ],
    lengthRange: [
      false,
      true
    ],
    slider: {
      label: 'color',
      thumbcolor: 'green darken-3',
      offsetVal: 0,
      color1: 'red',
      color2: 'blue',
      range: {
        min: -10,
        max: 10
      }
    },
    keyboardInputValue: '',
    offsetInput: 0,
    manufacturersListVisible: [
      { name: 'DUKE PT SL 300', order: 0, active: false },
      { name: 'JESTER GRIFFON SL 317', order: 1, active: true },
      { name: 'KINGPIN SL 300', order: 2, active: false },
      { name: 'XCELL SL 325', order: 3, active: true },
      { name: 'ugteuTLq', order: 15, active: false }
    ],
    manufacturersListConstant: [
      { name: 'DUKE PT SL 300', order: 0, active: false },
      { name: 'JESTER GRIFFON SL 317', order: 1, active: true },
      { name: 'KINGPIN SL 300', order: 2, active: false },
      { name: 'XCELL SL 325', order: 3, active: true },
      { name: 'ugteuTLq', order: 15, active: false }
    ],
    manufacturersListTEMP: [
      { name: 'Binding.0 vQ3kJ', order: 0, active: false },
      { name: 'Binding.1 NZdC9', order: 1, active: true },
      { name: 'Binding.2 3JgXX', order: 2, active: false },
      { name: 'Binding.3 QyWHM', order: 3, active: true },
      { name: 'Binding.4 2IJ61', order: 4, active: false },
      { name: 'Binding.5 uOTLq', order: 5, active: false },
      { name: 'Binding.6 934bb', order: 6, active: false },
      { name: 'Binding.7 vQ3kJ', order: 7, active: false },
      { name: 'Binding.8 NZddjf9', order: 8, active: false },
      { name: 'Binding.9 3JgoUodXX', order: 9, active: false },
      { name: 'Binding.10 kfkyWHM', order: 10, active: false },
      { name: 'Binding.11 2kjfjfIJ61', order: 11, active: false },
      { name: 'Binding.12 ufjTULq', order: 12, active: false },
      { name: 'Binding.13 fshf4bb', order: 13, active: false },
      { name: 'Binding.14 2ktetIJ61', order: 14, active: false },
      { name: 'Binding.15 ugteuTLq', order: 15, active: false }
    ],
    favoriteBinding: {
      data: [],
      selected: []
    },
    letterActivity: {
      a: true,
      b: true,
      c: true,
      d: true,
      e: true,
      f: true,
      g: true,
      h: true,
      i: true,
      j: true,
      k: true,
      l: true,
      m: true,
      n: true,
      o: true,
      p: true,
      q: true,
      r: true,
      s: true,
      t: true,
      u: true,
      v: true,
      w: true,
      x: true,
      y: true,
      z: true,
      ä: true,
      å: true,
      ö: true,
      numb: true
    }
  }),
  created () {
    console.log('ALERTTT PAGE CREATED...')
  },
  mounted () {
    this.GET_API_RASPBERRY('/bindings').then(
      resp => {
        console.log('BINDINGS')
        const data = resp.map(
          (binding, index) => {
            return { ...binding, active: false, order: index + 1 }
          }
        )
        console.log('Resp')
        console.log(data)
        this.manufacturersListConstant = data
        this.manufacturersListVisible = data
        const favoriteList = this.manufacturersListVisible.filter(e => e.active)
        this.favoriteBinding.selected = favoriteList
        console.log('favoriteList')
        console.log(favoriteList)
        this.manufacturersListVisible?.sort((a, b) => (a.active < b.active ? 1 : -1))
      }
    )
    // console.log(this.manufacturersListVisible?.sort((a, b) => (a.active < b.active ? 1 : -1)))
  },
  computed: {
    ...mapState([
      'valueEveryWhere'
    ]),
    favoriteBindingSelected () {
      return this.favoriteBinding.selected
    },
    notNullNotEmpty () {
      return [
        (v) => (!!v && v.length >= 4) || 'Ska ej vara tomt!'
      ]
    },
    numberRules () {
      return [
        (v) => (!isNaN(parseFloat(v)) && !!v && !v.toString().includes(',')) || 'Ska vara ifyllt och numeriskt!'
      ]
    },
    booleanRules () {
      return [
        (v) => this.booleanValues.includes(v) || 'This field is required !'
      ]
    },
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
        default:
          return 500
      }
    },
    getBreakPointName () {
      return this.$vuetify.breakpoint.name
    },
    dynamicFontStyles () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.getFontSizeStyle(20)
        case 'sm': return this.getFontSizeStyle(25)
        case 'md': return this.getFontSizeStyle(30)
        case 'lg': return this.getFontSizeStyle(35)
        case 'xl': return this.getFontSizeStyle(35)
        default:
          return this.getFontSizeStyle(30)
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions([
      'CHANGE_BINDING',
      'GET_API_RASPBERRY'
    ]),
    handleMenuNavigationButton (page) {
      this.$router.push(page)
    },
    selectedManufacturer (element) {
      console.log('This the manufacturer selected=')
      console.log(element)
      this.CHANGE_BINDING(element)
      this.$router.push('/sole_length')
    },
    showCharacter (element) {
      console.log('Parent received Character ')
      console.log(element)
      console.log('Character received!')
      switch (element) {
        case 'DELETE':
          console.log('=======> DELETE')
          if (this.keyboardInputValue !== null) {
            this.keyboardInputValue = this.keyboardInputValue.substring(0, this.keyboardInputValue.length - 1)
          }
          break
        case '123':
          console.log('=======> 123')
          break
        case 'SPACE':
          console.log('=======> space')
          if (this.keyboardInputValue === null) {
            this.keyboardInputValue = ' '
          } else {
            this.keyboardInputValue += ' '
          }
          break
        default:
          console.log('=======> space')
          if (this.keyboardInputValue === null) {
            this.keyboardInputValue = element
          } else {
            this.keyboardInputValue += element
          }
      }
    },
    buttonClicked (e) {
      console.log('buttonClicked>>>>>>>>>>>>>>>>>')
      if (e.group === 'diameter') {
        const range = e.range.map(e => false)
        range[e.index] = true
        this.diameterRange = range
        console.log(e)
      } else {
        const range = e.range.map(e => false)
        range[e.index] = true
        this.lengthRange = range
        console.log(e)
      }
    },
    handlePresetButton (e) {
      this.keyboardInputValue = e
    },
    getFontSizeStyle (fsize) {
      return {
        'font-size': `${fsize}px`
      }
    },
    handleBindingListItemClicked (listItem) {
      console.log('handleBindingListItemClicked')
      console.log(listItem)
      console.log('Find Ieme')
      const itemFound = this.manufacturersListVisible.find((item, index) => item.order === listItem.order)
      const itemFound2 = this.manufacturersListConstant.find((item, index) => item.order === listItem.order)
      if (itemFound.active) {
        console.log('CASE 1 -> ACTIVE=', itemFound.active)
        itemFound.active = false
        itemFound2.active = false
        const filterResult = this.favoriteBinding.selected.filter(e => e.order !== listItem.order)
        this.favoriteBinding.selected = filterResult
        console.log('filterResult')
        console.log(filterResult)
      } else {
        console.log('itemFound')
        console.log(itemFound)
        console.log('CASE 2. -> ACTIVE=', itemFound.active)
        if (this.favoriteBinding.selected.length < 4) {
          itemFound.active = !itemFound.active
          itemFound2.active = !itemFound2.active
          this.favoriteBinding.selected.push(itemFound)
          this.manufacturersListVisible = this.manufacturersListVisible.map(
            item => item.order === listItem.order ? { ...item, active: true } : item
          )
        }
      }
      console.log(itemFound)
    }
  },
  watch: {
    keyboardInputValue: function () {
      console.log('---- WATCHED --> VALUE changed-----')
      console.log(this.keyboardInputValue === null)
      console.log(this.keyboardInputValue === undefined)
      console.log(this.keyboardInputValue === '')
      console.log(this.keyboardInputValue === '')
      console.log(typeof this.keyboardInputValue)
      console.log(this.keyboardInputValue)
      const searchValue = this.keyboardInputValue.toLowerCase()
      if (this.keyboardInputValue !== null && this.keyboardInputValue !== '') {
        const includesCharacter = this.manufacturersListConstant.filter(
          x => x.name.toLowerCase().includes(searchValue)
        )
        const names = includesCharacter.map(e => e.name.toLowerCase())
        const nextCharacterArray = []
        const searchValueLastCharacter = searchValue.charAt(searchValue.length - 1)
        console.log('searchValueLastCharacter')
        console.log(searchValueLastCharacter)
        names.forEach(
          (element, index) => {
            for (let i = 0; i < element.length; i++) {
              if (element[i] === searchValueLastCharacter) {
                console.log('Type(i)=', typeof i, 'i=', i)
                if (i + 1 < element.length && element.includes(searchValue + element[i + 1])) {
                  console.log('ELEMENT=', element[i + 1])
                  const nextCharacter = element[i + 1]
                  console.log('ELEMENT+SEARCHVALUE=', searchValue + element[i + 1])
                  nextCharacterArray.push(nextCharacter)
                }
              }
            }
          }
        )
        Object.keys(this.letterActivity).forEach(
          (key) => {
            this.letterActivity[key] = false
          }
        )
        nextCharacterArray.forEach(
          (e) => {
            this.letterActivity[e] = true
          }
        )
        console.log('includesCharacter')
        console.log(names)
        console.log('nextCharacterArray')
        console.log(nextCharacterArray)
      } else {
        Object.keys(this.letterActivity).forEach(
          (key) => {
            this.letterActivity[key] = true
          }
        )
      }
      this.manufacturersListVisible = this.manufacturersListConstant.filter(
        x => x.name.toLowerCase().includes(searchValue)
      )
      // sort ? time complexity?
      this.manufacturersListVisible?.sort((a, b) => (a.active < b.active ? 1 : -1))
    },
    favoriteBindingSelected: function () {
      console.log('favoriteBinding CHANGED')
      console.log('favoriteBinding CHANGED')
      this.manufacturersListVisible?.sort((a, b) => (a.active < b.active ? 1 : -1))
    }
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
}

.button-speed-dial {
  /*
  position: fixed;
  top: 50%;
  left: 5px;
   */
}

.v-list-container {
  overflow-x: scroll !important;
  border: 2px solid var(--gravel);
  border-radius: 25px;;
  height: 100%;
}

.v-list--three-line .v-list-item, .v-list-item--three-line {
  min-height: fit-content;
}

.header {
  /* border: 3px solid blue;*/
  border-bottom: 1px solid var(--flame-pea);
  position: fixed;
  height: 7vh;
  right:0;
  left: 0;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer {
  /* border: 3px solid red;*/
  position: fixed;
  height: 11vh;
  right:0;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.main-content {
  /*border: 2px solid darkgoldenrod;*/
  margin-left: 0.5vh;
  margin-right: 0.5vh;
  margin-top: 8vh;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.1rem;
}

.button-group-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 90%;
}

.flex-col-diameter {
  /* border: 2px solid #7b02fc;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.keyboard-and-input-container {
  /*border: 2px solid yellowgreen;*/
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.keyboard-content {
}

.flex-col-depth {
  /*border: 2px solid #02fc23;*/
  width: 40%;
  height: 100%;
}

.favorite-button-container {
  /* border: 2px solid var(--flame-pea); */
  height: fit-content;
  border-radius: 10px;
  width: 100%;
  padding: 0.02rem;
  text-align: center;
}

.favorite-button-container .v-btn:not(.v-btn--round).v-size--default{
  width: fit-content;
  height: fit-content;
  padding: 0 10px;
}

.favorite-button {
  margin: 0.1rem;
  font-family: "Komika";
  border: 2px solid var(--flame-pea);
  border-radius: 5px;
  text-align: center;
  font-size: 10px;
}

.preset-button {
  width: 100%;
  height: 100%;
  border: 4px solid;
  border-color: var(--flame-pea);
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000040;
  background-color: var(--hint-of-red);
  font-family: "Komika";
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.preset-button-container {
  border: 2px solid #fc6202;
  height: 40%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.offset-container {
  border: 2px solid #3002fc;
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.offset-container-element {
  border: 2px solid #fc0272;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 2.5rem;
}

.preset-button {
  width: 100%;
  height: 100%;
  border: 4px solid;
  border-color: var(--flame-pea);
  border-radius: 10px;
  box-shadow: 0px 4px 4px #00000040;
  background-color: var(--hint-of-red);
  font-family: "Komika";
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.left-navigation {
  margin: 5px;
  height: 70vh;
  width: 10vh;
  position: fixed;
  top:15vh;
  left: 0;
  bottom: 15vh;
}

.text-flame-pea{
  color: var(--flame-pea);
  font-family: "Komika";
  font-size: var(--font-size-m);
  min-height: 30px;
  text-align: center;
}

.text-gravel{
  color: var(--gravel);
  font-family: "Komika";
  font-size: var(--font-size-m);
  min-height: 30px;
  text-align: center;
}

.right-navigation {
  /* border: 2px solid darkorange;*/
  margin: 5px;
  height: 70vh;
  width: 10vh;
  position: fixed;
  top:15vh;
  right: 0;
  bottom: 15vh;
}

.main-content-element-mobile {
  /*border: 3px solid deeppink;*/
  padding: 1rem;
  height: 100%;
  width: 100%;
}
.navigation-footer-button-large {
  display: flex;
  /* border: 2px solid greenyellow;*/
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  gap: 0.2rem;
  width: 100%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .v-list-container {
    overflow-x: scroll !important;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    height: 65%;
    min-height: 375px;
  }

  .favorite-button-container {
    /*border: 2px solid #0266fc;*/
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;
  }

  .favorite-button-container .v-btn:not(.v-btn--round).v-size--default{
    width: 100%;
  }
  .favorite-button {
    margin: 0.1rem;
    font-family: "Komika";
    text-align: center;
    font-size: 10px;
  }

  .left-navigation {
    display: none;
  }
  .right-navigation {
    display: none;
  }
  .main-content {
    margin-left: 0.5vh;
    margin-right: 0.5vh;
  }
  .input-offset {
    height: 12%;
  }
  .offset-container {
    border: 2px solid #3002fc;
    width: 100%;
    height: 100%;
  }
  .offset-container-element {
    gap: 0;
  }
  .preset-button-container {
    border: 2px solid #fc6202;
    display: flex;
    flex-direction: column;
  }

  .v-slider--vertical {
    min-height: 146px;
    /* margin-top: 12px; */
    /* margin-bottom: 12px; */
  }
}

</style>

<style scoped lang="scss">
%input-style {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 3px solid var(--gravel);
  border-radius: 5px;
  box-sizing: border-box;
  -moz-appearance: textfield;
  font-family: "Komika";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=text].input-sole-length {
  @extend %input-style;
  border: 2px solid var(--gravel);
  border-radius: 10px;
  font-size: 15px;
  font-family: "Museo";
  min-height: 3rem;
}

input[type=number].input-offset {
  @extend %input-style;
  text-align: center;
}
</style>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
</style>

<template>
  <!-- TODO: responsive style="height: 400px" -->
  <div class="keyboard-card-container">
    <!-- TODO: responsive style="height: 60px; min-width: 60px" -->
    <v-btn class="btn-component" @click="handleClickEvent('Q')" :disabled="!letterActivityTable.q">Q</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('W')" :disabled="!letterActivityTable.w">W</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('E')" :disabled="!letterActivityTable.e">E</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('R')" :disabled="!letterActivityTable.r">R</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('T')" :disabled="!letterActivityTable.t">T</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('Y')" :disabled="!letterActivityTable.y">Y</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('U')" :disabled="!letterActivityTable.u">U</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('I')" :disabled="!letterActivityTable.i">I</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('O')" :disabled="!letterActivityTable.o">O</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('P')" :disabled="!letterActivityTable.p">P</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('Å')"  :disabled="!letterActivityTable.å">Å</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('A')" :disabled="!letterActivityTable.a">A</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('S')" :disabled="!letterActivityTable.s">S</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('D')" :disabled="!letterActivityTable.d">D</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('F')" :disabled="!letterActivityTable.f">F</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('G')" :disabled="!letterActivityTable.g">G</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('H')" :disabled="!letterActivityTable.h">H</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('J')" :disabled="!letterActivityTable.j">J</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('K')" :disabled="!letterActivityTable.k">K</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('L')" :disabled="!letterActivityTable.l">L</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('Ö')"  :disabled="!letterActivityTable.ö">Ö</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('Ä')" :disabled="!letterActivityTable.ä">Ä</v-btn>
    <v-btn class="card-wide-2" @click="handleClickEvent('123')" :disabled="!letterActivityTable.numb">123</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('Z')" :disabled="!letterActivityTable.z">Z</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('X')" :disabled="!letterActivityTable.x">X</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('C')" :disabled="!letterActivityTable.c">C</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('V')" :disabled="!letterActivityTable.v">V</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('B')" :disabled="!letterActivityTable.b">B</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('N')" :disabled="!letterActivityTable.n">N</v-btn>
    <v-btn class="btn-component" @click="handleClickEvent('M')" :disabled="!letterActivityTable.m">M</v-btn>
    <v-btn class="card-wide-2"  @click="handleClickEvent('DELETE')">
      <v-icon large color="var(--gravel)">
        <!--  style="margin-left: 1.2em" -->
        mdi-backspace
      </v-icon>
    </v-btn>
    <div class="card-wide-3"></div>
    <v-btn class="card-wide-5"  @click="handleClickEvent('SPACE')"></v-btn>
    <div class="card-wide-3"></div>
  </div>
</template>

<script>

export default {
  name: 'KeyBoardComponent',
  props: {
    letterActivityTable: {
      type: Object
    }
  },
  data: () => ({
    variable: false,
    isDisabledLetter: true
  }),
  mounted () {
  },
  computed: {
  },
  filters: {
  },
  methods: {
    handleClickEvent (element) {
      console.log('Character Button Clicked')
      console.log('Emit character...')
      console.log(element)
      this.$emit('characterEntered', element)
      console.log('Character emitted!')
    }
  },
  watch: {
    /*
    getToBeAddedCustomer: function () {
    }
    */
  }
}
</script>

<style scoped>

.keyboard-card-container {
  margin: 0;
  width: 100%;
  height: 90%;
  display: grid;
  gap: 0.5rem;
  padding: 0.5rem;
  /*
  grid-template-columns: repeat(11, 1fr);
  grid-template-columns: 250px repeat(11, 1fr);
  */
  grid-template-columns: repeat(11, minmax(10px, 1fr));
  /* background-color: var(--hint-of-red);*/
  justify-content: center;
  /* height: 700px;*/ /*--- BREAK --- */
}

.keyboard-card-container .card-tall {
  /*
  grid-row: start / end;
      grid-row: span 2 / auto;
      start= span 2, span two rows
      end=auto
   */
  grid-row: span 2 / auto;
  font-family: "Komika";
  color: var(--gravel);
}

.keyboard-card-container .card-wide-2 {
  grid-column: span 2 / auto;
  font-family: "Komika";
  color: var(--gravel);
}

.keyboard-card-container .card-wide-3 {
  grid-column: span 3 / auto;
  font-family: "Komika";
  color: var(--gravel);
}

.keyboard-card-container .card-wide-5 {
  grid-column: span 5 / auto;
  font-family: "Komika";
  color: var(--gravel);
}

.btn-component {
  font-family: "Komika";
  color: var(--gravel);
  background-color: var(--hint-of-red);
  font-size: var(--font-size-m)
}

.v-btn:not(.v-btn--round) {
  color: var(--gravel);
  background-color: white;
  border: 2px solid #F56824;
  border-radius: 10px;
  min-width: 100%;
  height: 100%;
  min-height: 30px;
  width: 100%;
}
.v-btn.delete-button {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 10px;
}

</style>
